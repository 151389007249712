import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../layout"
import SEO from "../components/SEO"
import SingleBlogHeader from "../components/SingleBlogHeader"
import SingleBlogContent from "../components/SingleBlogContent"

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Template({ data, location }) {
    const {t} = useTranslation()

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
        hideBr
    >
      <SEO 
        domain={location?.hostname}/>
      <Helmet title={data.markdownRemark.frontmatter.title} />
      <SingleBlogHeader
        id="single-blog-header"
        img={data.markdownRemark.frontmatter.image}
        title={data.markdownRemark.frontmatter.title}
      />
      <SingleBlogContent
        location={location}
        id="single-blog-content"
        html={data.markdownRemark.html}
        date={data.markdownRemark.frontmatter.date}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPathES($slug: String!, $language: String!) {
    locales: allLocale(filter: {ns: {in: ["footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D[/]MM[/]YYYY")
        path
        title
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
